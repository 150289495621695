.work-title {
    display: flex;
    margin: 7vw 0 3vw 10vw;
    font-size: 3vw;
    font-weight: bold;
}

.line-break {
    height: 2px;
    background-color: black;
    margin: 0 10vw 3vw 10vw;
}

.work-space {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10vw 0 10vw;
    justify-content: space-around;
    row-gap: 3vw;
}

/*Card-decoration*/
.card {
    width: 35vw;
    height: 32vw;
    transition: transform 0.3s ease;
    font-family: var(--project-title-font);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    cursor: none;
}

.card-title {
    text-align: center;
    font-size: 2vw;
}

.card-link {
    text-decoration: none;
    color: black;
}

.project-img {
    width: 100%;
    height: 17vw;
    margin-bottom: 0.3vw;
}

.card-body {
    margin-top: 1vw;
}

.skill-used {
    display: flex;
    justify-content: flex-start;
    margin: 1vw 0.5vw 0 3vw;
}

.skill-title {
    font-size: 1.2vw;
    margin-right: 7vw;
}

.skill-txt {
    font-size: 1vw;
}

.duration {
    display: flex;
    justify-content: flex-start;
    margin: 0 0.5vw 0 3vw;
}

.duration-title {
    font-size: 1.2vw;
    margin-right: 5vw;
}

.duration-txt {
    font-size: 1vw;
}

.project-type {
    display: flex;
    justify-content: flex-start;
    margin: 0 0.5vw 0 3vw;
}

.project-type-title {
    font-size: 1.2vw;
    margin-right: 3vw;
}

.project-type-txt {
    font-size: 1vw;
}

/*Card-effect*/
.card:hover {
    transform: translateY(-10px);
    opacity: 80%;
}

.pizza-img {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4vw;
    height: 4vw;
    transform: translate(-50%, -50%);
    cursor: none;
    z-index: 1;
}

.card:hover .pizza-img {
    display: block;
}

.robot-img {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5vw;
    height: 5vw;
    transform: translate(-50%, -50%);
    cursor: none;
    z-index: 1;
}

.card:hover .robot-img {
    display: block;
}

.news-img {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4vw;
    height: 4vw;
    transform: translate(-50%, -50%);
    cursor: none;
    z-index: 1;
}

.card:hover .news-img {
    display: block;
}

.cards-img {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4vw;
    height: 4vw;
    transform: translate(-50%, -50%);
    cursor: none;
    z-index: 1;
}

.card:hover .cards-img {
    display: block;
}

@media screen and (max-width: 400px){
    .work-space {
        margin: 0 5vw 0 5vw;
        justify-content: space-around;
        row-gap: 3vw;
    }
    .project-img {
        height: 6rem;
    }
    .card {
        width: 9.3rem;
        height: 13rem;
    }
    .card-title {
        font-size: 0.7rem;
    }
    .card-body {
        margin-top: 0;
        padding: 0.5rem;
    }
    .skill-used {
        margin: 0 0.5rem;
    }
    .skill-title {
        font-size: 0.6rem;
        margin-right: 1rem;
    }
    .skill-txt {
        font-size: 0.5rem;
        margin-bottom: 0.3rem;
    }
    .duration {
        margin: 0 0.5rem;
    }
    .duration-title {
        font-size: 0.7rem;
    }
    .duration-txt {
        font-size: 0.6rem;
        margin-bottom: 0.3rem;
    }
    .project-type {
        margin: 0 0.5rem;
    }
    .project-type-title {
        font-size: 0.6rem;
    }
    .project-type-txt {
        font-size: 0.6rem;
        margin-bottom: 0;
    }
}