@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.greeting {
    margin: 10vw 0 0 10vw;
    text-align: left;
}

.greeting-txt {
    font-size: 5vw;
    font-family: "Kanit", sans-serif;
}

.animate-letters {
    display: flex;
}

.letter {
    font-size: 3vw;
    font-family: "Kanit", sans-serif;
    animation: LetterAnima 3.5s ease infinite;
}

.greeting-txt2 {
    font-size: 3vw;
    font-family: "Kanit", sans-serif;
}

@keyframes LetterAnima {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.profile-container {
    display: flex;
    margin: 5vw 0 0 10vw;
}

.profile-img {
    width: 2vw;
    height: 2vw;
    margin-right: 1vw;
}

.goback-btn {
    position: fixed;
    right: 3vw;
    bottom: 1vw;
    font-size: 3vw;
    color: #3a68b7;
}