.col-6 {
    width: 50% !important;
}
/*CS-section*/
.cs-container {
    margin: 2vw 14.5vw;
}

.solitaire-flip-card {
    background-color: transparent;
    width: 50vw;
    height: 19vw;
    perspective: 1000px;
    cursor: pointer;
}

.nexus-flip-card {
    background-color: transparent;
    width: 50vw;
    height: 26vw;
    perspective: 1000px;
    cursor: pointer;
}

.flipped {
    width: 50vw;
    height: 24vw;
}

.flip-card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    background-color: #f1dba0;
    border-radius: 8px;
}

.nexus-flip-card.flipped .flip-card-inner,
.solitaire-flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    padding: 2vw;
}

.flip-card-front {
    z-index: 2;
}

.flip-card-back {
    transform: rotateY(180deg);
    z-index: 1;
}

.cs-title {
    color: var(--intro-title-color);
    font-size: 1.5vw;
    margin-bottom: 1vw;
}

.c-list {
    font-size: 1.3vw;
    list-style-type: none;
    margin: 1vw 0 1vw -2vw;
}

.c-list i, .s-list i {
    margin-right: 1vw;
}

.s-list{
    font-size: 1.2vw;
    list-style-type: none;
    margin: 1vw 0 1vw -2vw;
}

.progress-item img {
    width: 100%;
}