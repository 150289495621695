@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.project {
    max-width: 100vw;
    height: 100vh;
}
.project-container {
    margin: 3vw 10vw 5vw 10vw;
    padding: 5vw 3vw 3vw 3vw;
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 1vw;
}
.git-img {
    width: 4vw;
    height: 4vw;
    position: absolute;
    right: 3vw;
    top: 3vw;
}
.project-title {
    font-family: var(--project-title-font);
    font-size: 3vw;
    text-align: center;
    margin-bottom: 5vw;
}

/*Introduction*/
.project-intro {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
.intro2 {
   width: 30%;
}
.intro3 {
    width: 30%;
}
.intro4 {
    width: 40%;
}
.intro-title {
    color: var(--intro-title-color);
    font-size: 1.5vw;
}
.intro2 ul {
    padding: 0;
}
.intro2-list {
    list-style-type: none;
    margin-bottom: 0.5vw;
}


/*Progress-section*/
.progress-title {
    font-size: var(--title-size);
    margin-bottom: 2vw;
    padding-bottom: 1.5vw;
    border-bottom: 1px solid black;
}
.progress-item {
    margin-bottom: 2vw;
}
.item1 {
    color: #40c4c4;
    font-size: 1.5vw;
}
.item2 {
    color: #9473e7;
    font-size: 1.5vw;
}

/*Description-section*/
.desc-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 3vw;
}
.desc-title {
    font-size: var(--title-size);
    padding-bottom: 1.5vw;
    border-bottom: 1px solid black;
}
.carousel-inner {
    width: 100%;
    height: 40vw;
}
.w-img {
    height: 37vw;
    border-radius: 5px;
    border: 1px solid black;
}

.version-img1 {
    position: absolute;
    top: 15vw;
    left: 15vw;
    width: 9vw;
    height: 5vw;
    z-index: 1;
}

.version-img2 {
    position: absolute;
    top: 15vw;
    left: 35vw;
    width: 9vw;
    height: 5vw;
    z-index: 1;
}

.w-title {
    font-size: 1.5vw;
    color: #2b7e8a;
    margin-bottom: 2vw;
}
.carousel-control-prev, .carousel-control-next {
    width: 7%;
}
.learn-btn {
    border-radius: 0 0 8px 8px;
}

.time-img1 {
    position: relative;
    top: 5vw;
    width: 25vw;
    height: 16vw;
    border: 1px solid black;
    border-radius: 5px;
    margin-right: 2vw;
}

.time-img2 {
    position: relative;
    top: 5vw;
    width: 25vw;
    height: 16vw;
    border: 1px solid black;
    border-radius: 5px;
    margin-right: 2vw;
}

.bi-arrow-right {
    font-size: 2.5vw;
    position: relative;
    top: 12vw;
    right: 1vw;
}

.w4-img-container {
    display: flex;
    width: 58vw;
    height: 30vw;
    background-color: #eae5e5;
    border-radius: 10px;
}

.draw-img1 {
    position: relative;
    top: 3vw;
    left: 15vw;
    width: 20vw;
    height: 10vw;
    border: 1px solid black;
    border-radius: 5px;
    margin-right: 2vw;
}

.draw-img2 {
    position: relative;
    top: 15vw;
    left: 2vw;
    width: 20vw;
    height: 10vw;
    border: 1px solid black;
    border-radius: 5px;
    margin-right: 2vw;
}

.carousel-container {
    height: 30vw;
}

.product-img {
    position: absolute;
    width: 60%;
}

.product-img1 {
    z-index: 0;
    top: 10vw;
    left: 0;
}

.product-img2 {
    z-index: 1;
    top: 6vw;
    left: 7vw;
}

.product-img3 {
    z-index: 2;
    top: 3vw;
    left: 15vw;
}

.product-img4 {
    z-index: 3;
    top: 0;
    left: 23vw;
}

.carousel-control-next {
    z-index: 5;
}

.what-to-eat-link {
    text-decoration: none;
    color: white;
}

.link-to-news {
    text-decoration: none;
    color: white;
}

.link-to-download {
    text-decoration: none;
    color: white;
}



@media screen and (max-width: 400px){
    .project-container {
        margin: 3rem 2rem 3rem 2rem;
        padding: 2rem 1rem 1rem 1rem;
        font-size: 0.5rem;
    }
    .project-title {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
    .git-img {
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
    .intro-title {
        font-size: 0.8rem;
    }
    .intro2 {
        width: 55%;
     }
     .intro3 {
         width: 45%;
     }
     .intro4 {
         width: 100%;
     }
     /* Progress */
    .progress-title {
        font-size: 1rem;
    }
    .item1 {
        font-size: 0.8rem;
        margin-bottom: 0.1rem;
    }
    .item2 {
        font-size: 0.8rem;
        margin-bottom: 0.1rem;
    }
    .item-txt {
        margin-bottom: 0.5rem;
    }
    
     /* Desc */
     .desc-title {
        font-size: 1rem;
    }
    .carousel-inner {
        height: 13rem;
    }
    .w-title {
        font-size: 0.7rem;
        margin-bottom: 2vw;
    }
    .w-img {
        height: 12rem;
    }
    .carousel-control-prev, .carousel-control-next {
        filter: invert(1);
    }
    .carousel-indicators [data-bs-target] {
        width: 1rem;
    }
    .learn-btn {
       font-size: 0.8rem;
    }
}