@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: #eae5e5;
  cursor: default;
}

:root {
  --project-title-font: "Kanit", sans-serif;
  --intro-title-color: #3a68b7;
  --title-size: 2vw;
}